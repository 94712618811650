import { API_URL } from '../env-constants';

function getUrlWithBase(url: string) {
  return `${API_URL}/${url}`;
}

const defaultHeaders = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

const apiClient = {
  async get<ResponseType>(url: string, params?: RequestInit) {
    let response = await fetch(getUrlWithBase(url), {
      method: 'GET',
      ...defaultHeaders,
      ...params,
    });

    if (response.status === 404) throw new Error('404 Not Found');

    return await response.json() as Promise<ResponseType>;
  },
  async post<ResponseType>(url: string, params?: RequestInit) {
    let response = await fetch(getUrlWithBase(url), {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Original-Referer': typeof document === 'undefined' ? '' : document.referrer,
        'Content-Type': 'application/json',
      },
      ...params,
      // ...(params.body ? { body: JSON.stringify(params.body) } : {}),
    });
    return await response.json() as Promise<ResponseType>;
  },
};

export default apiClient;
