import {
  PaletteMode, TextField, ThemeProvider, createTheme,
} from '@mui/material';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import client from '../apiClient';
import styles from './InputForm.module.scss';

import useDebouncedCallback from '../hooks/useDebounceCallback';
import { Theme } from '../store/theme';

type InputFormProps = {
  className?: string,
  children?: React.ReactNode,
};

type Errors = {
  email?: string;
};

const getDesignTokens = (mode: PaletteMode) => ({
  palette: {
    mode,
    ...(mode === 'dark'
      ? {
        primary: {
          main: '#6BD8C7', // --primary-500
        },
      }
      : {
        primary: {
          main: '#3CBFA6', // --primary-900
        },
      }),
  },
});

enum SubmitStatus {
  ERROR = 'error',
  SUCCESS = 'success',
}

const InputForm = React.forwardRef<HTMLFormElement, InputFormProps>(({
  className, children,
}, ref) => {
  const muiTheme = createTheme(getDesignTokens(Theme.DARK));
  const [emailValue, setEmailValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<Errors>({});
  const [isFormMutated, setIsFormMutated] = useState<boolean>(false);
  const [customServerError, setCustomServerError] = useState<string | null>(null);
  const [submitStatus, setSubmitStatus] = useState<SubmitStatus | null>(null);

  const validateForm = () => {
    setErrors({});

    let currentErrors: Errors = {};

    if (!!emailValue && !/\S+@\S+\.\S+/.test(emailValue)) {
      currentErrors.email = 'Please enter a valid email';
    } else if (emailValue === '') {
      currentErrors.email = 'Please provide your email address';
    } else if (emailValue.length > 125) {
      currentErrors.email = 'Email must be less than 125 characters';
    }

    setErrors(currentErrors);
    return Object.keys(currentErrors).length === 0;
  };

  const submitForm = async (data: { email: string }) => {
    setIsLoading(true);
    setSubmitStatus(null);

    try {
      await client.post('contact-form-lead/consultation?region=us', { cache: 'no-cache', body: JSON.stringify(data) });

      setEmailValue('');
      setIsFormMutated(false);
      setSubmitStatus(SubmitStatus.SUCCESS);
    } catch (e) {
      setCustomServerError('Failed to send an email');
      if (e.response.status === 429) {
        setCustomServerError('Too many attempts, please try again in a minute.');
      }
      console.error(e.message);
      setSubmitStatus(SubmitStatus.ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    setCustomServerError(null);
    event.preventDefault();
    setIsFormMutated(true);
    const isFormValid = validateForm();
    isFormValid && submitForm({ email: emailValue });
  };

  const validateMutatedForm = useDebouncedCallback(() => { validateForm(); }, 300);

  useEffect(() => {
    isFormMutated && validateMutatedForm();
  }, [emailValue, isFormMutated]);

  return (
    <form
      className={classnames(styles.form, className)}
      ref={ref}
      onSubmit={handleSubmit}
    >
      <ThemeProvider theme={muiTheme}>
        <TextField
          error={!!errors.email}
          helperText={!!errors.email && errors.email}
          value={emailValue}
          onChange={e => setEmailValue(e.target.value)}
          size='small'
          className={styles.input}
          id='emailField'
          label='Enter your email'
          variant='standard'
        />
      </ThemeProvider>

      <button
        disabled={isLoading || !!(emailValue && Object.keys(errors).length > 0)}
        type='submit'
        className={styles.submitButton}
        aria-label='Get consultation'
      >
        {isLoading ? (
          <img
            className={styles.spinner}
            src={`/media/spinner_${Theme.DARK}.webp`}
            alt=''
          />
        )
          : (
            <svg>
              <use
                xlinkHref='/media/arrow-right-bold.svg#arrowRight'
                href='/media/arrow-right-bold.svg#arrowRight'
              />
            </svg>
          )}

      </button>
      <p className={classnames(styles.status, {
        [styles.error]: submitStatus === SubmitStatus.ERROR,
        [styles.success]: submitStatus === SubmitStatus.SUCCESS,
      })}
      >
        {submitStatus === SubmitStatus.ERROR && (customServerError || 'Form submission error. Try again')}
        {submitStatus === SubmitStatus.SUCCESS && 'The form has been successfully submitted'}
      </p>
      {children}
    </form>
  );
});

export default InputForm;
