import { useAtom } from 'jotai';
import { useEffect, useRef, useState } from 'react';
import useInViewport from '../../hooks/useInViewport';
import clientDeviceSize, { ScreenSize } from '../../store/deviceScreenSize';
import styles from './FadeUpAnimatedText.module.scss';

interface FadeUpAnimatedTextProps {
  text: string,
}

const FadeUpAnimatedTextContent = ({ text }: FadeUpAnimatedTextProps) => {
  const textContainer = useRef<HTMLSpanElement>(null);
  const isTextVisible = useInViewport(textContainer);
  const [textOnScreen, setTextOnScreen] = useState<boolean>(false);
  const [textWidth, setTextWidth] = useState<number>(0);
  const deviceSize = useAtom<ScreenSize>(clientDeviceSize);

  useEffect(() => {
    isTextVisible && setTextOnScreen(true);
  }, [isTextVisible]);

  const updateTextLength = () => {
    if (textContainer.current) {
      const span = textContainer.current.querySelector(`.${styles.text}`);
      const letters = span?.querySelectorAll(`.${styles.letter}`);
      let length = 2;
      letters?.forEach(letter => {
        if (letter instanceof HTMLElement) {
          length += letter.offsetWidth;
        }
      });
      setTextWidth(length);
    }
  };

  useEffect(() => {
    const updateTextTimeout = setTimeout(updateTextLength, 500);
    return () => clearTimeout(updateTextTimeout);
  }, []);

  useEffect(updateTextLength, [deviceSize]);

  const showTitle = () => {
    if (textContainer.current) {
      const titleText = textContainer.current.querySelector(`.${styles.text}`);
      if (titleText) titleText.classList.add(styles.text_middle);
    }
  };

  useEffect(() => {
    text && textOnScreen && showTitle();
  }, [text, textOnScreen]);

  return (
    <mark
      className={styles.fadeUpTextWrap}
      style={{ width: textWidth }}
      ref={textContainer}
    >
      <div
        className={styles.text}
      >
        <div className={styles.text__container}>
          {text.split('')
            .map((letter, index) => (
              <span
                className={styles.letter} // eslint-disable-next-line react/no-array-index-key
                key={index}
              >
                {letter === ' ' ? <pre> </pre> : letter}
              </span>
            ))}
        </div>
      </div>
    </mark>
  );
};

export default FadeUpAnimatedTextContent;
