'use client';

import { ReactElement, useEffect, useState } from 'react';
import FadeUpAnimatedText from './FadeUpAnimatedText';

interface IFormattedTitle {
  firstPart: null | string,
  lastPart: null | string,
  animatedPart: null | string | ReactElement | ReactElement[],
}

export const ANIMATED_TEXT_REGEX = '\\*\\*[^*]+\\*\\*';

export enum AnimationType {
  FULL,
  PARTIAL,
  NONE,
}

type AnimatedTitleProps = { title: string, animationType?: AnimationType };

const AnimatedTitle = ({ title, animationType = AnimationType.PARTIAL }: AnimatedTitleProps) => {
  const [formattedTitle, setFormattedTitle] = useState<IFormattedTitle>({
    firstPart: null,
    lastPart: null,
    animatedPart: null,
  });

  useEffect(() => {
    if (title) {
      const isWithAnimation = title.match(ANIMATED_TEXT_REGEX);
      if (animationType === AnimationType.FULL) {
        setFormattedTitle({
          firstPart: null,
          lastPart: null,
          animatedPart: <FadeUpAnimatedText text={title} />,
        });
      } else if (isWithAnimation) {
        const divider = isWithAnimation.index;
        const textFirstPart = title.slice(0, divider);
        const textLastPart = divider !== undefined ? title.slice(divider + isWithAnimation[0].length) : '';
        setFormattedTitle({
          firstPart: textFirstPart,
          lastPart: textLastPart,
          animatedPart: animationType === AnimationType.NONE ? isWithAnimation[0].slice(2, isWithAnimation[0].length - 2)
            : (
              <FadeUpAnimatedText text={isWithAnimation[0].slice(2, isWithAnimation[0].length - 2)} />
            ),
        });
      } else {
        setFormattedTitle(prev => ({ ...prev, firstPart: title }));
      }
    }
  }, [title]);
  return (
    <>
      {formattedTitle.firstPart}
      {formattedTitle.animatedPart}
      {formattedTitle.lastPart}
    </>
  );
};

export default AnimatedTitle;
