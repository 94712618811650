import { IConsultation } from '@sapientpro/sapientpro-data-models';
import classnames from 'classnames';
import Image from 'next/image';
import React from 'react';
import AnimatedTitle from '../../../components/AnimatedTitle';
import InputForm from '../../../components/InputForm';
import styles from './Consultation.module.scss';

type ConsultationProps = {
  data: IConsultation,
  className?: string,
  homepageVersion?: boolean,
  formRef?: React.RefObject<HTMLFormElement>,
};
const Consultation = React.forwardRef<HTMLElement, ConsultationProps>(({
  data, className, homepageVersion, formRef,
}, ref) => (
  <section
    className={classnames(styles.consultation, className)}
    ref={ref}
  >
    <div className='container'>
      <div className={classnames(styles.content, {
        [styles.content_homepage]: homepageVersion,
      })}
      >
        <h2 className={classnames('sectionTitle', styles.title)}>
          <AnimatedTitle title={data?.title} />
        </h2>
        <p className={classnames('sectionSubtitle', styles.text)}>
          {data?.description}
        </p>
        <div className={styles.inputWrapper}>
          <InputForm
            ref={formRef}
            className={styles.inputWrapper__input}
          />
        </div>
      </div>
      <div className={styles.cubes}>
        <Image
          src='/media/consultation/cubes.svg'
          alt=''
          width={609}
          height={218}
        />
      </div>

    </div>
  </section>
));

export default Consultation;
