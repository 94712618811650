import React from 'react';
import FadeUpAnimatedTextContent from './FadeUpAnimatedTextContent';

const FadeUpAnimatedText = ({ text }: { text: string }) => (
  <>
    {text.split(' ').map((word, index, words) => (
      // eslint-disable-next-line react/no-array-index-key
      <React.Fragment key={index}>
        <FadeUpAnimatedTextContent text={word} />
        {index !== words.length - 1 && <span>&nbsp;</span>}
      </React.Fragment>
    ))}
  </>
);

export default FadeUpAnimatedText;
